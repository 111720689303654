html {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
}

body {
	background: #131b1f;
	overflow-x: hidden;
	font-family: "Exo 2", "sans-serif";
	font-size: 16px;
	height: 100%;
}

.App {
}

.headroom--unpinned,
.headroom--scrolled {
	z-index: 1050 !important;
}

.kentico-content ol,
.kentico-content ul {
	list-style: none;
	margin: 0.5em 0;
	padding-inline-start: 1rem;
	max-width: 80%;
	line-height: 1.5rem;
}
.kentico-content li:before,
.kentico-content li:before {
	content: "\2022";
	color: #c33c54;
	font-weight: bold;
	font-size: 18px;
	display: inline-flex;
	width: 1em;
	margin-left: -1rem;
	line-height: 1.5rem;
}

.kentico-content li + li {
	margin-top: 1rem;
}

.page {
	position: absolute;
	top: 4rem;
	left: 0;
	right: 0;
}

.page--start {
	top: 0;
}
.page--standard {
	top: 3rem;
}

.fade-appear .fade-enter {
	opacity: 0;
	z-index: 1;
}
.fade-appear-active .fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity 300ms linear 150ms;
}

.fade-exit {
	opacity: 1;
}

.fade-exit.fade-exit-active {
	opacity: 0;
	transition: opacity 150ms linear;
}
